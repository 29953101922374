<template>
<div>
       <el-skeleton style="width: 100%" animated v-if="!data">
            <template slot="template">
              <el-card
                class="card-grilla"
                shadow="always"
                style="margin-top: 30px"
                :body-style="{ padding: '0px' }"
              >
                <div style="padding: 14px;">
                  <el-skeleton-item variant="h3" style="width: 50%;" />
                  <div
                    style="display: flex; align-items: center; justify-items: space-between; margin-top: 16px; height: 16px;"
                  >
                    <el-skeleton-item variant="text" style="margin-right: 16px;" />
                    <el-skeleton-item variant="text" style="width: 30%;" />
                  </div>
                </div>
                <div style="padding: 14px;">
                  <el-skeleton-item variant="h3" style="width: 50%;" />
                  <div
                    style="display: flex; align-items: center; justify-items: space-between; margin-top: 16px; height: 16px;"
                  >
                    <el-skeleton-item variant="text" style="margin-right: 16px;" />
                    <el-skeleton-item variant="text" style="width: 30%;" />
                  </div>
                </div>
                <div style="padding: 14px;">
                  <el-skeleton-item variant="h3" style="width: 50%;" />
                  <div
                    style="display: flex; align-items: center; justify-items: space-between; margin-top: 16px; height: 16px;"
                  >
                    <el-skeleton-item variant="text" style="margin-right: 16px;" />
                    <el-skeleton-item variant="text" style="width: 30%;" />
                  </div>
                </div>
                <div style="padding: 14px;">
                  <el-skeleton-item variant="h3" style="width: 50%;" />
                  <div
                    style="display: flex; align-items: center; justify-items: space-between; margin-top: 16px; height: 16px;"
                  >
                    <el-skeleton-item variant="text" style="margin-right: 16px;" />
                    <el-skeleton-item variant="text" style="width: 30%;" />
                  </div>
                </div>
              </el-card>
            </template>
          </el-skeleton>

    <div class="datos" v-if="data && data.length">
     <el-row :gutter="5">
         <el-col :md="12" :offset="0">
             <el-card shadow="never" class="cuadro" style="">
                 <strong class="titulo"> <i class="el-icon-user-solid"></i>
                Datos de facturación</strong>
               
                
                <ul>
                    <li>Razón social: <strong>{{data[0].razon_social}}</strong></li>
                    <li>Nombre: <strong>{{data[0].cliente_nombre}}</strong></li>
                    <li>CUIT: <strong>{{data[0].cuit}}</strong></li>
                    <li>Correo: <strong>{{data[0].correo}}</strong></li>
                    <li>Teléfono: <strong>{{data[0].telefono}}</strong></li>
                </ul>
             </el-card>
             
         </el-col>
      
         <el-col :md="12" :offset="0">
             <el-card shadow="never" class="cuadro">
                 <strong  class="titulo"><i class="el-icon-truck"></i>
                 Datos de envío</strong>
                 
                <ul>
                    <li>Dirección: <strong>{{data[0].direccion}}</strong></li>
                    <li>Localidad: <strong>{{data[0].localidad}}</strong></li>
                    <li>Provincia: <strong>{{data[0].provincia}}</strong></li>
                    <!-- <li>Teléfono: <strong>{{data[0].telefono}}</strong></li> -->
                </ul>

                <!-- <el-button type="info" size="mini" v-if="puedeEditar" @click="cambiarDestino">Enviar a otro destino</el-button> -->
                
             </el-card>
         </el-col>
     </el-row>
         <div >
          <el-row :gutter="5">
               
            <el-col :md="12" :offset="0">
                
                 <el-card shadow="never" class="cuadro resumen">
                 <strong  class="titulo"><i class="el-icon-tickets"></i>
                 Resumen del pedido</strong>
                  
                <ul>
                  <li>Fecha: {{$date(data[0].fecha).format("DD/MM/YYYY HH:mm")}}</li>
                  <li>Items: {{ data.length }}</li>
                  <li>Cantidad: {{ totales().cantidad }}</li>
                  <li>Items con ofertas: {{ totales().ofertas }}</li>
                </ul>
                     <table class="table table-bordered table-pago">
                  <tbody>
<tr>
                    <td> SubTotal sin IVA</td>
                    <th>{{ totales().total | currency }}</th>
                  </tr>
                  <tr>
                    <td> IVA</td>
                    <th>{{ ((totales().total * 1.21) - totales().total)| currency }}</th>
                  </tr>
                    <tr>
                    <td> Total </td>
                    <th>{{ ((totales().total * 1.21) )| currency }}</th>
                  </tr>
                  </tbody>
                  
                </table>
              </el-card>
            </el-col>
          <el-col :md="12" :offset="0">
                
                 <el-card shadow="never" class="cuadro resumen">
                 <strong  class="titulo"><i class="el-icon-chat-line-square"></i>
                 Comentarios del pedido</strong>
                 <div v-if="puedeEditar()">

                 
                <p>
                    Si lo desea puede agregar comentarios al pedido.
                </p>
                 <el-input type="textarea"  v-model="comentarios" rows="4" placeholder="Comentarios del pedido"></el-input>
                 </div>
                 <div v-else style="background:#f1f1f1; padding:10px; margin-top:10px">
                    {{data[0].comentarios || 'No hay comentarios'}}
                 </div>
              </el-card>
            </el-col>
            <el-col :md="24"  :offset="0">
              
              <detalleProductos :data="data"/>
            </el-col>
            
            <el-col :md="{span:16, offset:8}">
              <!-- <el-card shadow="never" class=""> -->
                <div style="float:right">

                  <!-- <el-button type="info" class="btnContinuar" size="default" @click="verProductos">Productos</el-button> -->
                  <el-button type="primary" class="btnContinuar" :loading="cargando" size="default" @click="continuar">Continuar</el-button>
                </div>
                  
              <!-- </el-card> -->
            </el-col>
          </el-row>
        </div>
    </div>
    <!-- <modalDireccion /> -->
    </div>
</template>

<script>
import EventBus from '@/event-bus'
import { HTTP } from '@/axios'
    export default {
        props:['data'],
        components:{
          detalleProductos: () => import("@/components/detalleProductos.vue")
            //  modalDireccion: () => import("@/components/modales/modalDireccion.vue"),
        },
        data() {
            return {
                comentarios: '',
                cargando: false,
            }
        },
        methods: {
             goToHome()
    {
      console.log('tohome');
      if(this.$route.name === 'admin' || this.$route.name === 'detallePedido')
      {
         this.$router.push({name:'catalogo', query:{pagina:1}})
      }
      else{
this.buscado = ''
   setTimeout(() => {
        EventBus.$emit("eliminarFiltros", [],[]);
      }, 500);
      }
      
    },
            continuar()
            {

                if(this.$route.query.origen == 'finalizar')
                {
                      this.cargando = true
                                          HTTP.post("/noa/agregarComentarios", {comentarios: this.comentarios, pedido_id : this.data[0].pedido_id }).then((res) => {
                            console.log(res);
                            this.cargando = false
                              this.goToHome()
                            });
                }
                else if(this.$route.query.origen == 'admin')
                {
                    this.$router.push({name:'admin'})
                }
                else{
                    this.$router.push({name:'cuenta'})
                }
                  
               

            },
            cambiarDestino()
            {

            },
            verProductos(){
               if((this.$route.query.origen === 'admin') || (this.data && this.data[0].estado > 2)){
                return true;
              }
              else
                return false;
            },
            puedeEditar() {
              if(this.$route.query.origen === 'finalizar' && this.data && this.data[0].estado == 1){
                return true;
              }
              else
                return false;
            },
    totales() {
      let totales = {};

      this.total_cantidad = totales.cantidad = this.data.reduce((a, b) => {
        return a + b.cantidad;
      }, 0);
      totales.unitarios = this.data.reduce((u, uw) => {
        return parseFloat(u) + parseFloat(uw.precio_compra);
      }, 0);
      this.total_pedido = totales.total = this.data.reduce((t, tu) => {
        return parseFloat(t) + parseFloat(tu.precio_compra) * tu.cantidad;
      }, 0);

      totales.ofertas = this.data.filter((o) => {
        return o.oferta > 0;
      }).length;
      // console.log(totales);
      return totales;
    },
  },
    }
</script>

<style lang="scss" scoped>
.btnContinuar
{
  margin-top: 20px;
  width: 180px;
  display: inline-block;
  padding: 20px;
}
.datos{
    // border-top: 1px solid #ddd;
    margin-top: 20px;
}
.mensaje > i, .mensaje > span{
    display: inline-block;
}
.mensaje > i{
    font-size: 2em;
    margin-right: 10px;
}
.table-pago{
    margin-top: 5px;
    margin-left: 18px;
    width: 80%;
}
.table-pago > tbody > tr > td{
    // padding: 10px;
    width: 120px;
}
.table-pago > tbody > tr > td, .table-pago > tbody > tr > th{
    padding: 2px 6px;
    font-size: 14px;
    // width: 100px;
}
.titulo{
  border-bottom: 1px solid #000;
}
.cuadro > ul{
    margin:0;
    padding:0;
    margin-top: 10px;
    padding-left: 20px;
}
.cuadro{
    font-size: 16px;
    // padding: 20px;
    text-align: left;
    // background: #000;
    // border-radius: 0px 0px 8px 7px;
    color: #333;
    // width: 80%;
    margin: 0 auto;
    // border-top: 1px solid #ddd;
    // border-bottom: 1px solid #ddd;
    // border-right: 1px solid #d9473e;
    // border-left: 1px solid #d9473e;
    // box-shadow: 2px 1px 3px #ddd;
    height: 200px;
}
.br
{
    border-right:1px solid #ddd;
}
.bt
{
    border-top:1px solid #ddd;
    margin: 5px auto;
}
.resumen{
    margin-top: 5px;
    height: 230px
}

@media screen and (max-width:768px) {
  .resumen{
    margin-top: 10px;
    height: auto;
}
  .cuadro{
    height: auto;
  }
}
</style>